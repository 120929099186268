@layer components {

  .button-container {
    @apply w-fit rounded-lg font-semibold duration-300 ring-1 ring-inset ring-transparent whitespace-nowrap cursor-pointer;
    &.btn-primary {
      @apply bg-primary-50 ring-primary-50 text-white enabled:hover:bg-primary-50/70 active:scale-95;
    }
    &.btn-secondary {
      @apply bg-primary-90/50 text-black enabled:hover:bg-primary-80 active:scale-95;
    }
    &.btn-tertiary {
      @apply bg-transparent text-black enabled:hover:bg-primary-90/50 active:scale-95;
    }
    &.btn-outline {
      @apply enabled:hover:bg-primary-50/60 hover:text-white text-primary-50 ring-primary-50 active:scale-95;
    }
  }
}
